var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('v-dialog',{staticClass:"DK_derivation",attrs:{"max-width":"900"},model:{value:(_vm.showDeriveModal),callback:function ($$v) {_vm.showDeriveModal=$$v},expression:"showDeriveModal"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline grey lighten-2 flexTitle"},[_vm._v(" Derivar "),_c('v-btn',{staticStyle:{"color":"black"},attrs:{"icon":"","dark":"","left":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"DK_derivation",staticStyle:{"padding-top":"1rem"}},[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',{staticClass:"DK_derivation"},_vm._l((_vm.columns),function(column,ke){return _c('td',{key:ke,staticStyle:{"padding":"0"}},[(_vm.showDeriveModal)?_c('v-list',{staticStyle:{"margin":"0 1rem","border-radius":"5px !important"},attrs:{"nav":"","dense":""}},[_c('th',[_vm._v(_vm._s(column.title))]),_c('v-subheader',[_c('v-text-field',{staticStyle:{"margin-bottom":"0.5rem"},attrs:{"label":"Buscar...","hide-details":"auto"},model:{value:(_vm.searchOption[column.root]),callback:function ($$v) {_vm.$set(_vm.searchOption, column.root, $$v)},expression:"searchOption[column.root]"}})],1),_c('v-list-item-group',{staticClass:"Columns__Search",staticStyle:{"max-height":"150px","overflow":"auto"},attrs:{"color":"primary"}},[(
                          column.title == 'Subtrámites' &&
                          _vm.loggedUser.worker.actualTurn &&
                          _vm.loggedUser.worker.actualTurn.queue.services
                        )?_c('span',_vm._l((_vm.loggedUser.worker.actualTurn.queue.services.filter(
                            function (o) { return o.name
                                .toLowerCase()
                                .includes(_vm.searchOption[column.root]); }
                          )),function(item,i){return _c('v-list-item',{key:i,attrs:{"link":""},on:{"click":function($event){return _vm.selectOption(column.root, item)}}},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name)}})],1)],1)}),1):_vm._e(),(column.title == 'Trámites')?_c('span',_vm._l((_vm.derivableData.filter(function (o) { return o.name
                              .toLowerCase()
                              .includes(_vm.searchOption[column.root]); }
                          )),function(item,i){return _c('v-list-item',{key:i,attrs:{"link":""},on:{"click":function($event){return _vm.selectOption(column.root, item)}}},[_c('v-list-item-content',{on:{"click":function($event){return _vm.selectTram(item.queues)}}},[_c('v-list-item-title',[_c('span',{staticClass:"itemMenu"},[_vm._v(_vm._s(item.name))])])],1)],1)}),1):_vm._e(),(column.title == 'Sala De Espera')?_c('span',_vm._l((_vm.rooms.filter(function (o) { return o.name
                              .toLowerCase()
                              .includes(_vm.searchOption[column.root]); }
                          )),function(item,i){return _c('v-list-item',{key:i,attrs:{"link":""},on:{"click":function($event){return _vm.selectOption(column.root, item)}}},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name)}})],1)],1)}),1):_vm._e()])],1):_vm._e()],1)}),0)])]},proxy:true}])})],1),_c('v-card-actions',{staticClass:"modal-footer pr-3"},[_c('v-btn',{attrs:{"color":"sucess","disabled":!_vm.selected.destinationQueueGroup,"loading":_vm.derivableActions.loading},on:{"click":_vm.handleDerivate}},[_vm._v(" Derivar ")]),_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.close}},[_vm._v(" Cancelar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }