<template>
  <div class="text-center">
    <v-dialog v-model="showDeriveModal" max-width="900" class="DK_derivation">
      <v-card>
        <!-- <v-progress-linear
          indeterminate
          color="cyan"
          v-if="turnLoading"
        ></v-progress-linear> -->
        <v-card-title class="headline grey lighten-2 flexTitle">
          Derivar
          <v-btn icon dark @click="close" left style="color: black">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="DK_derivation" style="padding-top: 1rem">
          <v-simple-table>
            <template v-slot:default>
              <!-- <thead>
                <tr>
                  <th v-for="(column, k) in columns" :key="k" class="text-left">
                    {{ column.title }}
                  </th>
                </tr>
              </thead> -->
              <tbody>
                <tr class="DK_derivation">
                  <td
                    v-for="(column, ke) in columns"
                    :key="ke"
                    style="padding: 0"
                  >
                    <v-list
                      nav
                      dense
                      style="margin: 0 1rem; border-radius: 5px !important"
                      v-if="showDeriveModal"
                    >
                      <th>{{ column.title }}</th>
                      <v-subheader>
                        <v-text-field
                          label="Buscar..."
                          v-model="searchOption[column.root]"
                          hide-details="auto"
                          style="margin-bottom: 0.5rem"
                        ></v-text-field>
                      </v-subheader>
                      <v-list-item-group
                        color="primary"
                        style="max-height: 150px; overflow: auto"
                        class="Columns__Search"
                      >
                        <span
                          v-if="
                            column.title == 'Subtrámites' &&
                            loggedUser.worker.actualTurn &&
                            loggedUser.worker.actualTurn.queue.services
                          "
                        >
                          <v-list-item
                            v-for="(
                              item, i
                            ) in loggedUser.worker.actualTurn.queue.services.filter(
                              (o) =>
                                o.name
                                  .toLowerCase()
                                  .includes(searchOption[column.root])
                            )"
                            :key="i"
                            link
                            @click="selectOption(column.root, item)"
                          >
                            <v-list-item-content>
                              <v-list-item-title
                                v-text="item.name"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </span>

                        <span v-if="column.title == 'Trámites'">
                          <v-list-item
                            v-for="(item, i) in derivableData.filter((o) =>
                              o.name
                                .toLowerCase()
                                .includes(searchOption[column.root])
                            )"
                            :key="i"
                            link
                            @click="selectOption(column.root, item)"
                          >
                            <v-list-item-content
                              @click="selectTram(item.queues)"
                            >
                              <v-list-item-title>
                                <span class="itemMenu">{{ item.name }}</span>
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </span>

                        <span v-if="column.title == 'Sala De Espera'">
                          <v-list-item
                            v-for="(item, i) in rooms.filter((o) =>
                              o.name
                                .toLowerCase()
                                .includes(searchOption[column.root])
                            )"
                            :key="i"
                            link
                            @click="selectOption(column.root, item)"
                          >
                            <v-list-item-content>
                              <v-list-item-title
                                v-text="item.name"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </span>
                      </v-list-item-group>
                    </v-list>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>

        <v-card-actions class="modal-footer pr-3">
          <v-btn
            color="sucess"
            @click="handleDerivate"
            :disabled="!selected.destinationQueueGroup"
            :loading="derivableActions.loading"
          >
            Derivar
          </v-btn>
          <v-btn color="error" @click="close"> Cancelar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "deriveModal",
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    workstationid: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      showDeriveModal: false,
      columns: [
        {
          title: "Subtrámites",
          root: "service",
        },
        {
          title: "Trámites",
          root: "destinationQueueGroup",
        },
        {
          title: "Sala De Espera",
          root: "waitingRoom",
        },
      ],

      searchOption: {
        service: "",
        destinationQueueGroup: "",
        waitingRoom: "",
      },
      selected: {
        destinationQueueGroup: "",
        hasPriority: false,
        service: "",
        waitingRoom: "",
      },
      rooms: [],
    };
  },
  destroyed() {
    this.setderivableQueueGroups([]);
  },
  computed: {
    ...mapState({
      loggedUser: (state) => state.loggedUserSilver.loggedUserData,
      derivableData: (state) => state.loggedUserSilver.derivableQueueGroups,
      derivableActions: (state) => state.derivateSilver,
    }),
  },
  methods: {
    ...mapMutations(["setderivableQueueGroups"]),
    ...mapActions([
      "fetchCustomerDetails",
      "toDerivate",
      "toAggregated",
      "getWorkstationTurn",
      "setDataForDerivation",
    ]),
    open() {
      this.option = null;
      this.showDeriveModal = true;
    },
    async close() {
      (this.searchOption = {
        service: "",
        destinationQueueGroup: "",
        waitingRoom: "",
      }),
        (this.selected = {
          destinationQueueGroup: "",
          hasPriority: false,
          service: "",
          waitingRoom: "",
        }),
        (this.rooms = []);
      this.showDeriveModal = false;
    },
    selectTram(value) {
      if (value.length > 0) {
        this.rooms = value[0].getWaitingRooms || [];
      } else {
        this.rooms = [];
      }
    },
    selectOption(key, value) {
      if (key == "destinationQueueGroup" || key == "waitingRoom") {
        this.selected[key] = { id: value.id };
      } else {
        this.selected[key] = value;
      }
    },
    async handleDerivate() {
      this.selected = this.sanitize(this.selected);
      if (this.loggedUser.worker.actualTurn) {
        this.setDataForDerivation({
          "originalQueue.id": this.loggedUser.worker.actualTurn.queue.id,
          "turn.id": this.loggedUser.worker.actualTurn.id,
        });
      }
      await this.toDerivate(this.selected);
      await this.toAggregated();
      this.close();
      setTimeout(() => {
        this.$emit("finishCall");
      }, 2000);
    },
    sanitize(obj) {
      var r = obj;
      for (var k in r) if (!r[k] || r[k] == "") delete r[k];
      return obj;
    },
  },
  filters: {
    capitalize: function (str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
  },
  watch: {},
};
</script>
<style>
table {
  width: 100%;
  max-width: 100%;
  text-align: center;
}

th {
  font-weight: 600;
  height: 50px;
}
.modal-footer {
  /* padding: 10px 0px 10px 71% !important; */
  border-top: 1px solid #e0e0e0;
}

.Columns__Search {
  min-height: 210px;
  min-width: 210px;
}

.flexTitle {
  display: flex;
  justify-content: space-between;
}

.Card {
  background-color: #ffffff;
  border-radius: 5px !important;
  margin: 0 1rem;
}

.DK_derivation {
  background: #eff0f5;
}

.itemMenu {
  white-space: normal;
  max-width: 210px;
}
</style>
